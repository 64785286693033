<!--
  PACKAGE_NAME : src\pages\ai\llm
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2024-09-13
  DESCRIPTION : 운영관리 > LLM > LLM Tester
-->
<template>
  <div>
    <div class="page-sub-box">
      <EspDxDataGrid :data-grid="dataGrid" :ref="dataGrid.refName" @saving="onSaveData" @editor-preparing="onEditorPreparing" />
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },

    data() {
      return {
        dataGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'llmTesterGrid',
          excel: {
            autoFilterEnabled: true,
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSource: [],
          // width:'100%',     // 주석처리시 100%
          // height: '100%', // 주석처리시 100%
          apiActionNm: {},
          scrolling: {
            mode: 'standard',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            saving: true,
            editorPreparing: true,
          },
          showActionButtons: {
            excel: false,
            select: false,
            update: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_msgContents('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDelete();
                  },
                },
                location: 'before',
              },
            ],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '프로젝트 아이디',
              i18n: 'LLM.WORD.PROJECT_ID',
              alignment: 'center', // left center right
              dataField: 'id',
              visible: false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '프로젝트',
              i18n: 'LLM.WORD.PROJECT',
              alignment: 'center', // left center right
              dataField: 'name',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowEditing: true,
              allowGrouping: false,
              requiredRule: {
                message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                  value: this.$_msgContents('LLM.WORD.PROJECT', { defaultValue: '프로젝트' }),
                  defaultValue: '[프로젝트] 은/는 필수값 입니다'
                }),
              },
            },
            {
              caption: '작업 설정',
              i18n: 'LLM.WORD.WORK_CONFIG',
              width: 250,
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              allowEditing: false,
              cellTemplate: (container, options) => {
                if (options.data.id !== undefined) {
                  let button = new DxButton({
                    propsData: {
                      text: this.$_msgContents('LLM.WORD.WORK', { defaultValue: '작업' }),
                      elementAttr: { class: 'white light_filled txt_XS' },
                      value: options.data,
                      onClick: () => {
                        this.onClickWorkConfig(options.data.id, options.data.name);
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '테스트 작업',
              i18n: 'LLM.WORD.TEST_WORK',
              width: 250,
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              allowEditing: false,
              cellTemplate: (container, options) => {
                if (options.data.id !== undefined) {
                  let button = new DxButton({
                    propsData: {
                      text: this.$_msgContents('LLM.WORD.DETAIL', { defaultValue: '보기' }),
                      elementAttr: { class: 'white light_filled txt_XS' },
                      value: options.data,
                      onClick: () => {
                        this.onClickTestWork(options.data.id);
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '등록일',
              i18n: 'COMPONENTS.REGISTRATION_DATE',
              width: 250,
              alignment: 'center', // left center right
              dataField: 'insert_date',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowEditing: false,
              allowGrouping: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },

    computed: {},

    methods: {
      /** @description 데이터 조회 메서드 */
      async selectDataList() {
        const payload = {
          actionname: 'LLM_PROJECT_LIST',
          data: {},
          loading: false,
        };
        const res = await this.CALL_LLM_API(payload);
        if (res.status === 200) {
          this.dataGrid.dataSource = res.data;
        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 데이터 저장 메서드 */
      async onSaveData(e) {
        e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

        // 변경된 값이 없으면 메시지 출력
        if (e?.changes.length === 0) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
          return;
        }

        let isDuplicateConfigKey = false;

        const changeNameArr = e.changes.map(d => d.data);
        const removeDuplicateNameArr = new Set(changeNameArr.map(d => d.name));
        if (changeNameArr.length !== removeDuplicateNameArr.size) {
          isDuplicateConfigKey = true;
        }

        if (!isDuplicateConfigKey) {
          let payload = {
            actionname: 'LLM_PROJECT_EXIST',
            data: changeNameArr,
            loading: false,
          };
          let res = await this.CALL_LLM_API(payload);
          if (res.status === 200) {
            if (res.data) {
              isDuplicateConfigKey = true;
            }
          } else {
            this.$_Msg(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            return false;
          }
        }

        if (isDuplicateConfigKey) {
          this.$_Msg(
            this.$_msgContents('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS', {
              value: this.$_msgContents('LLM.WORD.PROJECT', { defaultValue: '프로젝트' }),
              defaultValue: '[프로젝트] 값은 중복될 수 없습니다.'
            }),
          );
          return;
        }

        let saveData = [];

        e.changes.forEach(d => {
          let keyExpr = this.dataGrid.keyExpr ? this.dataGrid.keyExpr : 'id'; // ex) id
          let dataKey = d.key; // ex) 1
          let dataMap = d.data; // ex) { value: 100, name: 'test' }

          let data = { ...dataMap };

          if (d.type === 'update') {
            data[keyExpr] = dataKey;
          }

          saveData.push(data);
        });

        let payload = {
          actionname: 'LLM_PROJECT_SAVE',
          data: saveData,
          loading: false,
        };

        let res = await this.CALL_LLM_API(payload);
        if (res.status === 200) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          await this.selectDataList();
        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 데이터 삭제 메서드 */
      async onDelete() {
        let selectedRows = this.$refs.llmTesterGrid.getGridInstance.getSelectedRowsData();

        if (selectedRows.length <= 0) {
          this.$_Msg(this.$_msgContents('CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다' }));
          return;
        }

        if (
          !(await this.$_Confirm(
            this.$_msgContents('LLM.MESSAGE.WARNING_DELETE_PROJECT', { defaultValue: '프로젝트 삭제 시 모든 데이터가 함께 삭제됩니다. <br>삭제하시겠습니까?' }),
          ))
        ) {
          return;
        }

        const payload = {
          actionname: 'LLM_PROJECT_DELETE',
          data: selectedRows,
          loading: false,
        };

        const res = await this.CALL_LLM_API(payload);
        if (res.status === 200) {
          this.$_Toast(this.$_msgContents('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다' }), { icon: 'success' });
          await this.selectDataList();
        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 작업 설정 클릭 메서드 */
      onClickWorkConfig(id, name) {
        this.$router.push({ path: '/ai/llm/work-config/list', query: { id, name } });
      },

      /** @description 테스트 작업 클릭 메서드 */
      onClickTestWork(id) {
        this.$router.push({ path: '/ai/llm/test-work/list', query: { id: id } });
      },

      /** @description 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.insert_date) {
          return this.$_commonlib.formatDate(rowData.insert_date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },

      /** @description 프로젝트 이름 입력 포멧 체크 */
      onEditorPreparing(e) {
        if (e.dataField === 'name') {
          e.editorOptions.onKeyPress = function (args) {
            const event = args.event;
            const char = String.fromCharCode(event.which || event.keyCode);

            // 한글, 영문, 숫자, 공백을 제외한 모든 문자를 차단
            if (!/^[ㄱ-ㅎ가-힣a-zA-Z0-9 ]*$/.test(char)) {
              event.preventDefault(); // 허용되지 않은 문자일 경우 입력 차단
            }
          };
        }
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.selectDataList();
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {

    },
  };
</script>

<style lang="scss" scoped>
</style>
